/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 37번째 로그에서는 캡처보드, Cloudflare 장애, 44bits 테라폼 무크지 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/1768796"
  }, "팟캐스트 개발자 팟캐스트 stdout.fm 방송듣기, : 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/AVerMedia-Live-Gamer-Ultra-GC553/dp/B07DHV47HF/"
  }, "Amazon.com: AVerMedia Live Gamer Ultra")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=0jTTy8LvTX8"
  }, "오픈핵 2019 익산 행사장 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.co.kr/electronics/cyber-shot-compact-cameras/dsc-rx100m3-dsc-rx100m3g"
  }, "DSC-RX100 III 컴팩트 디지털 카메라 | Cyber-shot 포켓 카메라 | Sony KR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://obsproject.com/"
  }, "Open Broadcaster Software | OBS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/359"
  }, "하시코프 사용자 모임 세 번째 서울 밋업 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCzOPDpymGngXyLySgUy0QxA"
  }, "서초루비 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bluedesigns.jp/products/yeti/"
  }, "Blue - Yeti")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/awskrug/events/262480608/"
  }, "AWSKRUG 스타트업 소모임 (7월9일) | Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/cloudflare-outage/"
  }, "Cloudflare outage caused by bad software deploy (updated)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/ko-kr/waf/"
  }, "클라우드 WAF(웹 애플리케이션 방화벽) | Cloudflare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/plans/"
  }, "Our Plans | Pricing | Cloudflare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/discordapp/status/1143140103511932929"
  }, "Discord on Twitter: \"Sorry for the trouble everyone, Wumpus tripped over the power cord. ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.discordia.me/wumpus"
  }, "The Discord Wiki")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/how-verizon-and-a-bgp-optimizer-knocked-large-parts-of-the-internet-offline-today/"
  }, "How Verizon and a BGP Optimizer Knocked Large Parts of the Internet Offline Today")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gureum/gureum/releases/tag/1.10.0"
  }, "Release Mojave 업데이트 · gureum/gureum")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/hatemogi/AewolInput"
  }, "hatemogi/AewolInput: 애월 한글 입력기 for OSX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/nacyot/yasin-peurojegteu-yagu-deiteo-sigaghwa-peurojegteu"
  }, "야신 프로젝트 - 야구 데이터 시각화 프로젝트 - Speaker Deck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/terraform_introduction_infrastrucute_as_code"
  }, "테라폼(Terraform) 기초 튜토리얼: AWS로 시작하는 Infrastructure as Code | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techbookfest.org/"
  }, "技術書典 | 技術書典")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kdp.amazon.com/en_US/"
  }, "Self Publishing | Amazon Kindle Direct Publishing")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1452"
  }, "Terraform Enterprise의 원격 상태 관리 :: Outsider's Dev Story")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://git-scm.com/book/ko/v2"
  }, "Git - Book")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://pyrasis.com/docker.html"
  }, "PYRASIS.COM: 가장 빨리 만나는 Docker - 목차")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hanbit.co.kr/realtime/"
  }, "리얼타임 eBook")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
